import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/site-wide/layout/layout";
import Divider from "../components/common/divider";

export default () => {
  return (
    <>
      <Layout title="Общи условия">
        <Container className="mt-5 pt-5">
          <div className="text-center mb-5">
            <h1>Общи условия</h1>
            <Divider />
          </div>

          <div className="mb-5">
            <div>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Всяко лице, наричано за краткост „потребител“, получило достъп
                  до уеб сайт с адрес
                </span>{" "}
                <a href="https://klucharite.eu/">
                  <span style={{ fontWeight: 400 }}>
                    https://klucharite.bg/
                  </span>
                </a>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  , наричан по-нататък „сайт“, и до информацията и услугите в
                  него, наричани за краткост „Съдържание“, което предлага
                  уебсайта на&nbsp; Локсмит Експерт ООД, наричан по-долу „Доставчик“,
                  се съгласява и приема следните Общи условия за ползване.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Потребителят, използващ този сайт, се задължава да спазва тези
                  Общи условия, както и всички разпоредби на българското и
                  международното законодателство. При несъгласие с общите
                  условия Потребителят е длъжен да преустанови незабавно
                  ползването на сайта. Доставчикът не носи отговорност, ако
                  потребителят не се е запознал с настоящите условия.
                </span>
              </p>
              <p>&nbsp;</p>
              <h3>
                <strong>1. ОБЩИ УСЛОВИЯ за ползване на уеб сайта</strong>
              </h3>
              <p>
                <span style={{ fontWeight: 400 }}>
                  1.1. Потребителят има право да използва този сайт, както
                  информацията и материалите, съдържащи се в него, само за
                  позволени от закона цели, без да накърнява правата и да
                  ограничава достъпа до този сайт на трета страна.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  1.2. Ползването на сайта е по инициатива, желание и за сметка
                  на Потребителя, при спазване ограниченията в ЗАПСП в полза на
                  титуляря на авторското право – Локсмит Експерт ООД или трета страна.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  1.3. Потребителят има право да разглежда, съхранява, копира и
                  отпечатва материалите от този сайт единствено за лична
                  употреба с цел да се информира за&nbsp; услугите, предлагани
                  от Доставчика, и/или да възлага поръчки или иска оферти
                  относно услугите в сайта.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  1.4. На потребителя е забранено следното:
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  1.4.1. Да променя, копира, възпроизвежда (цялостно или
                  частично), публикува, изпраща (по какъвто и да е начин),
                  заема, продава, създава вторични материали на базата на
                  съдържанието в сайта, да използва с търговска цел или да
                  разпространява по какъвто и да е друг начин никакъв елемент от
                  съдържанието на този сайт, без предварително писмено
                  разрешение от Локсмит Експерт ООД. Без такова разрешение не се
                  разрешава никакво използване на съдържанието в друг сайт или
                  електронна среда с каквато и да е цел.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  1.4.2. Да използва неправомерно сайта или съдържанието в него
                  (в т.ч., но не само, изпращане или предаване на материали със
                  заплашително, невярно, подвеждащо, оскърбително, обидно,
                  нарушаващо конкуренцията, оклеветяващо, неприлично,
                  порнографско или друг вид незаконно съдържание или каквито и
                  да са материали, които представляват или подбуждат поведение,
                  което може да бъде квалифицирано като престъпление, да доведе
                  до гражданска или наказателна отговорност или по друг начин да
                  наруши законите на Република България).
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  1.4.3. Изрично не се разрешава използването на какъвто и да е
                  софтуер или устройства с цел достъп и масово копиране/сваляне
                  на съдържанието в сайта.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  1.4.4. Да използва сайта, за да получи неоторизиран достъп до
                  други компютърни системи или мрежи.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  1.5. Локсмит Експерт ООД си запазва правото да осъвременява (добавя,
                  променя, премества и изтрива) съдържанието или части от него
                  (вкл. цени, публикации, данни, услуги или друга информация,
                  показана на или свързана с настоящия сайт), да коригира
                  евентуални допуснати грешки, неточности и пропуски по всяко
                  време без предизвестие.
                </span>
              </p>
              <p>&nbsp;</p>
              <h3>
                <strong>2. Ограничаване на отговорността</strong>
              </h3>
              <p>
                <span style={{ fontWeight: 400 }}>
                  2.1. Пълното съдържание на сайта се предоставя "такова,
                  каквото е". Доставчикът е предприел всички необходими мерки да
                  гарантира достоверността, истинността и пълнотата на
                  информацията тук, както и отсъствието на подвеждащи данни.
                  Въпреки това Локсмит Експерт ООД не гарантира точността и пълнотата
                  на съдържанието в сайта.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  2.2. Използването на сайта, както и действията, които
                  потребителят предприема вследствие на това, са отговорност
                  изцяло на потребителя.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  2.3. При никакви обстоятелства Локсмит Експерт ООД, нейните
                  служители, управители, доставчици или други трети лица,
                  споменати на този сайт или участвали в неговото създаване, не
                  носят отговорност за каквито и да са щети (в това число, но не
                  само, преки, косвени, наказателни, специални, случайни,
                  предизвикани, последващи вреди, щети от пропуснати ползи, от
                  загуба на информация или прекъсване на дейността или други),
                  причинени по какъвто и да е друг начин, свързани или
                  произтекли от използването, невъзможността да бъде използван
                  или липсата на достъп до сайта или на материал, публикуван в
                  него, както и боравенето с него (в т.ч. технически проблеми,
                  независимо от положените от Доставчика усилия) или на други
                  сайтове, към които този сайт препраща, както и на други
                  материали, информация, продукти или услуги, които се съдържат
                  в някои от тези сайтове, независимо от основанието и липсата
                  на предупреждение за евентуалното възникване на вреди и дори и
                  когато Доставчикът е бил уведомен за вероятността от
                  настъпване на подобни щети.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  2.4. В случаите извън посочените тук, отговорността на
                  Локсмит Експерт ООД се ограничава до максимално допустимата степен,
                  разрешена от законите на Република България.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  2.5. Ползването на този сайт може да води до разходи от страна
                  на Потребителя, като такси за доставка на Интернет връзка,
                  мобилен достъп до Интернет и други. Доставчикът не дължи на
                  Потребителя никакви суми във връзка с каквито и да са разходи
                  за ползването на този сайт.
                </span>
              </p>
              <p>&nbsp;</p>
              <h3>
                <strong>3. Препратки (хипервръзки)</strong>
              </h3>
              <p>
                <span style={{ fontWeight: 400 }}>
                  3.1. Възможно е в сайта да има хипервръзки към уебсайтове,
                  източници или рекламодатели, които са собственост на трети
                  страни. Доставчикът не наблюдава тези препратки в Сайта , тъй
                  като те са предоставени само за улеснение на потребителите.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  3.2. Използвайки препратките, Потребителят е наясно, че
                  напуска този сайт. Доставчикът Локсмит Експерт ООД не носи
                  отговорност за достъпа към тези външни сайтове, както и за
                  тяхното съдържание, пълнота, актуалност, достоверност и
                  полезност на информацията, рекламите, продуктите или други
                  материали, станали достъпни на или чрез тях. Доставчикът не
                  носи отговорност за политиката относно защитата на личните
                  данни или сигурността на тези сайтове. Ползването им е
                  отговорност единствено на Потребителя. Потребителите следва да
                  предявяват претенциите си към администратора или уебмастъра на
                  съответния външен сайт.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  3.3. Препратки от трети страници към сайта са допустими, ако
                  са извършени в подходящ контекст. Ако са ви необходими
                  уточнения за възможността да поставите препратка във вашия уеб
                  сайт към страница от този сайт, моля, свържете се с нас.
                </span>
              </p>
              <h3>
                <strong>4. Авторско право</strong>
              </h3>
              <p>
                <span style={{ fontWeight: 400 }}>
                  4.1. Дизайнът, структурата и съдържанието на този уеб сайт са
                  защитени с авторско право. Последните са собственост на
                  Локсмит Експерт ООД, която притежава изключителното право за
                  ползването им. Всяко неоторизирано ползване на съдържанието е
                  нарушение на авторски права или други законови разпоредби.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  4.2. Потребителите нямат право да копират, публикуват,
                  продават, променят или лицензират части или цялото Съдържание
                  на този уеб сайт, както и да го използват с каквато и да е
                  публична или търговска цел, освен като информация за
                  популяризиране на услугите на Локсмит Експерт ООД.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  4.3. Този уеб сайт може да съдържа материали, названия или
                  търговски марки, които са собственост на други компании,
                  организации и сдружения, защитени от Закона за авторското
                  право и сродните му права (ЗАПСП).
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  4.4. Изрично не се разрешава използването на каквото и да е
                  Съдържание от сайта, освен по начин, който не противоречи на
                  настоящите условия за ползване, без писменото разрешение на
                  собственика Локсмит Експерт ООД.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  4.5. Достъпът до този сайт както и до всяка част от
                  съдържанието му не дава по никакъв начин право за ползване на
                  интелектуалната собственост без предварителното писмено
                  разрешение на автора Локсмит Експерт ООД.
                </span>
              </p>
              <p>&nbsp;</p>
              <h3>
                <strong>5. Поверителност</strong>
              </h3>
              <p>
                <span style={{ fontWeight: 400 }}>
                  5.1. Доставчикът Локсмит Експерт ООД е регистриран администратор на
                  лични данни и гарантира неприкосновеността на всички лични
                  данни, включително, но не само, името на потребителя и/или
                  имейл адрес. Те ще бъдат използвани единствено за целите, за
                  които те са били събрани или получени, и няма да бъдат
                  предоставяни на трети страни.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  5.2. Лични или идентификационни данни няма да бъдат използвани
                  с друга цел без изрично писмено съгласие от страна на
                  Потребителя, освен в случаите, предвидени от закона.
                  Доставчикът няма да ползва получената информация за изпращане
                  на нежелани съобщения (спам).
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  5.3. Доставчикът не носи отговорност за личната идентифицираща
                  информация, която Потребителят разкрива пред други сайтове
                  чрез ползване на линковете към тях, намиращи се на сайта.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  5.4. Информацията, предоставяна от Потребителя при посещението
                  на този сайт, се използва за отговаряне на отправените
                  запитвания, за подобряване на сайта, за връзка с
                  потребителите, за маркетингови и информационни цели, за
                  приемане на заявки и изпращане на услуги до потребителите и
                  др. По време на посещението на сайта, Доставчикът получава
                  информация за броя на посетителите, IP адреса на Потребителя,
                  датата и часа на посещението, източника на заявката и други
                  данни, които може да ползва за целите на своята вътрешна
                  статистика и системно администриране.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  5.5. Локсмит Експерт ООД има право да променя политиката за
                  поверителност като част от условията за ползване на сайта по
                  всяко време. Задължение на потребителя е да се запознава със
                  Съдържанието и настъпилите промени в него. Датата на последна
                  актуализация на тази страница е отбелязана в началото на
                  условията за ползване на сайта.
                </span>
              </p>
            </div>
          </div>
        </Container>
      </Layout>
    </>
  );
};
